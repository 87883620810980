export const currentTheme = (location) => {
  let path = location
    ? location.pathname
    : typeof window !== `undefined`
    ? window.location.pathname
    : "/"
  let search = location
    ? location.search
    : typeof window !== `undefined`
    ? window.location.search
    : ""

  let searchStored = ""

  if (typeof sessionStorage !== `undefined`) {
    searchStored = sessionStorage.getItem("utm_medium")
  }

  if (search?.includes("utm_medium=app") || searchStored === "app") {
    if (typeof sessionStorage !== `undefined`) {
      sessionStorage.setItem("utm_medium", "app")
    }
    return "app"
  }
  // all men pages but /men/core need men theme
  if (
    path.indexOf("/men/") === 0 ||
    path.indexOf("/sms/offer/men/") === 0 ||
    path.indexOf("/redesign/components/headervariantmen") === 0 ||
    path.indexOf("/golfdigest") === 0
  ) {
    return "men"
  }
  return "master"
}
