import React from "react"
import { Box } from "@rebass/grid/emotion"

import { TextElementNova } from "../../../../../nutrafol-ui-kit/src/TextElementNova"
import { Link } from "gatsby"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { ACCPATH, menuItems } from "./data"
import { LowerWrapBox, LowerWrapContainer, LinkFlex } from "./header.styled"
import LogoutButton from "../../../account/logoutbutton"
import EllipseOrange from "../../../../assets/icons/nova/ellipse-orange.svg"
import LowerWrapMobile from "./headerlowerwrapmobile"

const isCurrent = (link) => {
  const pathname = typeof window !== "undefined" ? window.location.pathname : ""
  const search = typeof window !== "undefined" ? window.location.search : ""
  //console.log(link, pathname, search,)
  if (search.includes("subId")) {
    return link === "/account/subscriptions/"
  } else if (link === ACCPATH) {
    return pathname === ACCPATH
  } else {
    return pathname.includes(link)
  }
}

const handleAccountNavClick = (destination, text) => {
  segmentEvent("Account Navigation Clicked", {
    destination,
    text,
  })
}

const LowerWrap = ({ isAccountPage }) => {
  if (!isAccountPage) {
    return null
  }

  return (
    <LowerWrapBox>
      <LowerWrapMobile
        handleAccountNavClick={handleAccountNavClick}
        menuItems={menuItems}
        isCurrent={isCurrent}
      />
      <LowerWrapContainer className="lower-wrap is-menu">
        {menuItems.map((item) => {
          if (item.link?.includes("logout")) {
            return (
              <Box
                width={["auto"]}
                className={`menu-item-wrap logout`}
                key={item.title}
              >
                <LogoutButton
                  isAccountLink={true}
                  isVariant={true}
                  fontClass={`account-menu-item  Body-10-Dia-Normal text-nova-transparency-black-70`}
                />
              </Box>
            )
          } else {
            return (
              <Box
                width={["auto"]}
                key={item.link}
                className={`menu-item-wrap`}
              >
                <Link
                  to={item.link}
                  onClick={() => handleAccountNavClick(item.link, item.title)}
                  className={
                    `account-menu-item Body-10-Dia-Normal ` +
                    (isCurrent(item.link) ? ` ` : "")
                  }
                >
                  <LinkFlex>
                    {isCurrent(item.link) ? (
                      <Box>
                        <EllipseOrange />
                      </Box>
                    ) : null}
                    <Box>
                      <TextElementNova
                        text={item.title}
                        className={
                          `account-menu-item ` +
                          (isCurrent(item.link)
                            ? ` Body-10-Dia-Strong text-nova-base-black current`
                            : " Body-10-Dia-Normal text-nova-transparency-black-70")
                        }
                      />
                    </Box>
                  </LinkFlex>
                </Link>
              </Box>
            )
          }
        })}
      </LowerWrapContainer>
    </LowerWrapBox>
  )
}

export default LowerWrap
