import styled from "@emotion/styled"
import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { Flex } from "@rebass/grid/emotion"

export const RibbonLink = styled.a`
  flex: 1;
  cursor: pointer;
  width: 100%;
  padding: 8px 0;
  @media (min-width: 420px) {
    .special-br-1 {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    padding: 8px 0;
  }
  @media (min-width: 1024px) {
    padding: 8px 0;
  }

  &.ribbon-ab-test-a {
    //background: green;
    padding: 16px 0 20px;
    @media (min-width: 1024px) {
      padding: 18px 0 22px;
    }
  }

  .mobile-break {
    @media (min-width: 451px) {
      display: none;
    }
  }

  .desktop-only {
    display: none;
    @media (min-width: 1024px) {
      display: inline-block;
    }
  }
`

export const RibbonContainer = styled(Flex)`
  transition: none;
  color: ${theme.wild.color.nova.base.black};
  position: absolute;
  min-height: 32px;
  border-top: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  @media (min-width: 1024px) {
    border-top: none;
    border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    border-left: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    border-top-right-radius: 2px;
    position: relative;
    margin-top: 0;
    top: 0;
    padding: 0 12px;
    &.ribbonsub {
      margin-top: 4px;
    }
  }

  .ribbon-special-br {
    display: block;
    @media (min-width: 400px) {
      display: none;
    }
  }
`

export const RibbonContainerSimple = styled(Flex)`
  transition: none;
  background: ${theme.wild.color.nova.base.solid[20]};
  color: ${theme.wild.color.nova.base.black};
  position: absolute;
  min-height: 35px;
  margin-top: 4px;
  //border-top: 1px solid #c8d2d1;
  top: 92px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow:
    0px 11px 15px 0px rgba(0, 87, 82, 0.08),
    0px 9px 46px 0px rgba(0, 87, 82, 0.06),
    0px 24px 38px 0px rgba(0, 87, 82, 0.06);
  border-top: none !important;
  border-radius: 8px;

  @media (max-width: 1023px) {
    width: 98%;
    left: 0;
    right: 0;
    margin: auto;
    top: 64px;
  }

  .ribbon-special-br {
    display: block;
    @media (min-width: 400px) {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    position: relative;
    border-top: none;
    margin-top: 0;
    top: 0;
    padding: 0 12px;
    &.ribbonsub {
      margin-top: 4px;
    }
  }
`

export const CloseIconWrap = styled.button`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    padding: 7px;
  }
`
