import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { TextElementNova } from "../../../../../nutrafol-ui-kit/src/TextElementNova"
import CartIcon from "../../../../assets/icons/nova/cart.svg"
import CartContext from "../../../../context/CartContext"
import { handleNavigationSegmentEvent } from "./utils/segmentEvents"

const envUrl = process.env.GATSBY_MAGENTO_URL

const MiniCartWrapper = styled.div`
  position: relative;
`
const CartIconHolder = styled.div`
  position: relative;

  svg {
    width: 24px;
    height: 24px;
  }
`

const CartQty = styled.span`
  color: ${theme.wild.color.men.base.white};
  background: ${theme.wild.color.men.brand.teal[80]};
  min-width: 12px;
  min-height: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: -4px;
  right: -4px;
  padding: 2px 2px;

  &.hide {
    display: none;
  }

  .mono {
    font-size: 9px !important;
    line-height: 9px !important;
  }
`

const HeaderCartIcon = () => {
  const [cartGender, setCartGender] = useState("")

  const cartState = useContext(CartContext)

  useEffect(() => {
    const getCart = () => {
      cartState.setIsCartFetching(true)
      fetch(`${envUrl}rest/V1/nutrafol-carts/`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (typeof resJson !== "object") {
            const cartJson = JSON.parse(resJson)
            cartState.setCart(cartJson)
            if (typeof cartJson === "object") {
              let gender = ""
              if (cartJson.items !== []) {
                if (Object.keys(cartJson.items).length > 0) {
                  Object.keys(cartJson.items).forEach((item) => {
                    let product = cartJson.items[item]
                    if (product?.product_gender === "Women") {
                      gender = "women"
                    }
                  })
                }
              }
              setCartGender(gender)
            }
          }
        })
        .catch((error) => {
          console.warn("Fetch error: ", error)
        })
        .finally(() => cartState.setIsCartFetching(false))
    }

    getCart()
  }, [setCartGender])

  return (
    <MiniCartWrapper>
      <Link
        to={`/checkout/cart`}
        className="icon-holder icon-cart"
        id="cart-total-wrap"
        onClick={() =>
          handleNavigationSegmentEvent({
            destination: "/checkout/cart/",
          })
        }
      >
        <CartIconHolder>
          <CartIcon />
        </CartIconHolder>
        <CartQty
          className={`num-holder ${cartState.cartNum < 1 ? "hide" : ""}`}
        >
          <span id="cart-total-items">
            <TextElementNova
              text={cartState.cartNum}
              className="mono text-nova-base-white"
            />
          </span>
        </CartQty>
      </Link>
      <input type="hidden" id="cart-gender" value={cartGender} />
    </MiniCartWrapper>
  )
}

export default HeaderCartIcon
