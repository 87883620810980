import React from "react"
import { graphql } from "react-apollo"
import styled from "@emotion/styled"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { TextElementNova } from "../../../nutrafol-ui-kit/src/TextElementNova"

import { setCookie } from "../../utils/cookies"
import {
  clearSubscriptionLocalStorage,
  clearSubscriptionSessionStorage,
  clearCartLocalStorage,
} from "../../utils/general"
import { logoutUserMutation } from "../../queries/logoutuser"

const bearerToken = process.env.GATSBY_MAGENTO_API_BEARER_TOKEN

const LogOutButton = styled.button`
  appearance: none;
  &.mobile {
    display: block;
    width: 100%;
    padding: 14px 0;
    margin: 0 24px;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 28px;
    }
    &.men:not(.font-gotham) {
      font-size: 28px;
      margin: 0 !important;
    }
  }
`

const LogoutButton = (props) => {
  const {
    isNavLink,
    fontClass,
    isMen,
    mutate,
    isAccountLink = false,
    isVariant = false,
  } = props

  // necessary to make magento session invalid
  const logoutSession = () => {
    fetch(
      `${process.env.GATSBY_MAGENTO_URL}rest/V1/nutrafol-customers/session-logout`,
      {
        method: "POST",
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    )
      .then(() => {
        /*
         * if ada exists, remove token(s)
         * and user email, so the user
         * is logged out in the chat
         */
        if (window.adaEmbed) {
          window.adaEmbed.setMetaFields({
            existing_token: "",
            gatsbytoken: "",
          })
        }
        /*
         * remove gatsbytoken cookie so apollo
         * doesn't include bearer token in req
         */
        setCookie("gatsbytoken", "", 5)
        clearCartLocalStorage()
        //clear sub id in local storage
        clearSubscriptionLocalStorage()
        clearSubscriptionSessionStorage()
        // delay and redirect to login screen - need hard refresh to reload session from magento
        setTimeout(() => {
          window.location =
            process.env.GATSBY_MAGENTO_URL + "customer/account/login/"
        }, 1500)
      })
      .catch((error) => {
        console.warn("Fetch error: ", error)
      })
  }

  // mutation to make token invalid
  const revokeToken = (e) => {
    e.preventDefault()
    mutate({})
      .then(() => {
        // invalidate magento session
        logoutSession()
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  if (isAccountLink) {
    return (
      <LogOutButton
        onClick={revokeToken}
        className="text-left md:text-center md:mx-auto"
      >
        {isVariant ? (
          <TextElementNova
            element="p"
            text={`Log Out`}
            className={`${fontClass}`}
          />
        ) : (
          <TextElement
            element="p"
            text={`Log Out`}
            className={`${fontClass}`}
          />
        )}
      </LogOutButton>
    )
  } else {
    return (
      <form onSubmit={revokeToken}>
        <fieldset>
          <legend className="hidden">{`log out`}</legend>
          {isNavLink ? (
            <LogOutButton type="submit">{`Log Out`}</LogOutButton>
          ) : (
            <LogOutButton
              type="submit"
              className={`${fontClass} ${
                isMen ? "men" : "font-grilliItalic"
              } mobile text-master-base-black`}
            >{`Log Out`}</LogOutButton>
          )}
        </fieldset>
      </form>
    )
  }
}

export default graphql(logoutUserMutation)(LogoutButton)
