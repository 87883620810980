import { PRODUCT_IMAGES } from "../../../../utils/imagesMap"

const children = [
  {
    name: "My Nutrafol",
    url: "/account/",
  },
  {
    name: "Orders",
    url: `/account/orders/`,
  },
  {
    name: "Subscriptions",
    url: `/account/subscriptions/`,
  },
  {
    name: "Profile",
    url: "/account/profile/",
  },
  {
    name: "Rewards",
    url: "/account/rewards/",
  },
  {
    name: "Get Support",
    url: "/contact-us/",
  },
  {
    name: "Log Out",
    url: "",
  },
]

export const data = [
  {
    name: "products",
    link: {
      text: "Products",
      url: "#",
    },
    children: [
      {
        title: "Nutrafol Men",
        safeName: "Nutrafol Men",
        subtext:
          "Multi-target key root causes of thinning <span class='whitespace-nowrap'>from within.</span>",
        subtextDesktop:
          "Multi-target key root causes of thinning <span class='whitespace-nowrap'>from within.</span>",
        image: PRODUCT_IMAGES.MENPRO1.label3,
        url: "/men/products/thinning-hair-growth-supplement/",
      },
      {
        title: "NUTRAFOL MEN + DHT INHIBITOR",
        safeName: "NUTRAFOL MEN + DHT INHIBITOR",
        subtext:
          "Address thinning along the hairline and decrease <span class='whitespace-nowrap'>scalp coverage.</span>",
        subtextDesktop:
          "Address thinning along the hairline and decrease <span class='whitespace-nowrap'>scalp coverage.</span>",
        image: PRODUCT_IMAGES.MENDEF1.label4,
        url: "/men/products/hairline-thinning-dht-blocker-supplement/",
      },
      {
        title: "Nutrafol Men <span class='special-br'></span>+ Hair Serum",
        safeName: "Nutrafol Men + Hair Serum",
        subtext:
          "Address all-over thinning from the <span class='whitespace-nowrap'>inside and out.</span>",
        subtextDesktop:
          "Address all-over thinning from the <span class='whitespace-nowrap'>inside and out.</span>",
        image: PRODUCT_IMAGES.MENFUL1.label4,
        url: "/men/products/thinning-hair-supplements-and-serum/",
      },
    ],
  },
  {
    name: "results",
    link: {
      text: "Results",
      url: "/men/results/",
    },
  },
  {
    name: "faq",
    link: {
      text: "FAQ",
      url: "/men/faq/",
    },
  },
  {
    name: "stories",
    link: {
      text: "Stories",
      url: "/men/stories/",
    },
  },
  {
    name: "account",
    isMobileOnly: true,
    link: {
      text: "Account",
      url: "#",
    },
    children,
  },
]
