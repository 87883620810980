import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import DOMPurify from "dompurify"

/**
 * Primary UI component for Text
 * Text Design System
 * https://www.figma.com/design/2N4gMTqYSm9oGSouVxbvCW/Nutrafol---Website-2024?node-id=1-3111&m=dev&ready-for-dev=1
 */
const validElements = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "p",
  "span",
  "button",
]
const invalidElements = validElements.filter(
  (el) => !["span", "button"].includes(el)
)
const showWarning =
  typeof window === "object" && window.location.hostname.includes("localhost")

const clsPrefix = `textelement nova `
const colorPrefix = ` text-nova-base-black `

export const TextElementNova = ({
  text = ``,
  element,
  onClick = () => {},
  onKeyPress = () => {},
  classes = [],
  className = ``,
  styles,
  sanitize = false,
  children,
}) => {
  /*
   *  classes prop handler
   *  we set default as mobile first class
   *  then detecting breakpoints
   */
  let css = ``
  let classNameDefault = ``
  if (classes.default) {
    const { novaFonts } = require("../styles/nova-fonts.js")
    classNameDefault = classes?.default
    const classesKeys = Object.keys(classes)
      .sort((a, b) => a - b)
      .filter((el) => el !== "default")

    const buildStyle = (obj) => {
      //console.log(obj)
      let res = ``
      for (let k in obj) {
        res += `\t${k}: ${obj[k]} !important;\n`
      }
      return res
    }

    let bp0 = classesKeys[0]
    let bp1 = classesKeys[1]
    let temp

    //add first media
    if (bp0) {
      temp = novaFonts[classes[bp0]]
      if (temp) {
        css += `@media (min-width: ${bp0}px) {\n${buildStyle(
          temp[bp0 < 1024 ? 1 : 2]
        )}}\n`
      } else {
        console.warn(`invalid class passed ${classes[bp0]}`)
      }
    }

    //add second media
    if (bp1) {
      temp = novaFonts[classes[bp1]]
      if (temp) {
        css += `@media (min-width: ${bp1}px) {\n${buildStyle(
          temp[bp1 < 1024 ? 1 : 2]
        )}}\n`
      } else {
        console.warn(`invalid class passed ${classes[bp1]}`)
      }
    }
  }

  if (showWarning && css) {
    console.info(css)
  }

  const Element = validElements.includes(element) ? element : "p"
  // p can contain only Phrasing content  - span, button etc https://nutrafol.atlassian.net/browse/NT2-11229
  if (Element === `p`) {
    invalidElements.forEach((el) => {
      if (
        text &&
        typeof text.includes === "function" &&
        text.includes(`<${el}`)
      ) {
        //replace all non Phrasing content with a SPAN
        showWarning &&
          console.warn(
            `TextElement: <${el.toUpperCase()}> in <P> replaced with <SPAN>`,
            text
          )
        const reg1 = new RegExp(`(<${el})`, "igm")
        const reg2 = new RegExp(`(</${el})`, "igm")
        text = text.replace(reg1, "<span").replace(reg2, "</span")
      }
    })
  }
  // styles added based on classes
  const StyledTextElement = styled(Element)`
    ${css}
  `

  let fullClassName = `${clsPrefix} ${className} ${classNameDefault}`

  if (!fullClassName.includes(`text-nova-`)) {
    fullClassName += colorPrefix
  }

  if (React.isValidElement(text)) {
    return (
      <StyledTextElement
        className={fullClassName}
        style={styles}
        onClick={onClick}
        onKeyPress={onKeyPress}
      >
        {sanitize ? DOMPurify.sanitize(text, { ALLOWED_TAGS: [] }) : text}
      </StyledTextElement>
    )
  }

  if (children) {
    return (
      <StyledTextElement
        className={fullClassName}
        style={styles}
        onClick={onClick}
        onKeyPress={onKeyPress}
      >
        {children}
      </StyledTextElement>
    )
  }

  return (
    <StyledTextElement
      className={fullClassName}
      style={styles}
      onClick={onClick}
      onKeyPress={onKeyPress}
      dangerouslySetInnerHTML={{
        __html: sanitize
          ? DOMPurify.sanitize(text, { ALLOWED_TAGS: [] })
          : text,
      }}
    />
  )
}

TextElementNova.propTypes = {
  /**
   * text or html string or node
   */
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  /**
   * what element to render
   */
  element: PropTypes.string,
  /**
   * Optional string of classes
   */
  className: PropTypes.string,
  /**
   * Optional object of styles
   */
  styles: PropTypes.object,
  /**
   * Optional onClick handler
   */
  onClick: PropTypes.func,
  /**
   * Optional onKeyPress handler
   */
  onKeyPress: PropTypes.func,
  /**
   * Optional classes array
   */
  classes: PropTypes.object,
}

TextElementNova.defaultProps = {
  element: "p",
}
