import React, { useState, useRef, useContext } from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"

import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { classNames } from "../../../../utils/general"
import { renderRichTextDinamo } from "../../../../utils/contentful"
import UserContext from "../../../../context/UserContext"

const ribbonMap = {
  subpush: {
    copy: "Put your hair growth plan on autopilot with a Nutrafol<br class='show md:hidden'/> subscription and  <span class='font-dinamoBold'>see results in as little as 3 months.</span>",
    href: null,
  },
  pick_your_booster: {
    copy: "{name}, celebrate with a free booster. <span class='font-dinamoBold'>Pick yours!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  pay_it_forward: {
    copy: "{name}, keep growing with $5 off. <span class='font-dinamoBold'>Apply now!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  upgrade_booster: {
    copy: "{name}, save 5% on renewals. <span class='font-dinamoBold'>Add your booster!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  free_gift: {
    copy: "{name}, celebrate with a gift. <span class='font-dinamoBold'>Pick yours now!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  refer_a_friend: {
    copy: "{name}, <i>refer friends and save!</i>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
}

const RibbonLink = styled.a`
  flex: 1;
  cursor: pointer;
  width: 100%;
  font-family: ${theme.wild.font.dinamo.regular};
  font-size: 11px;
  line-height: 16px;
  padding: 4px;

  .special-br-1 {
    display: none;
  }

  @media (min-width: 350px) and (max-width: 420px) {
    .special-br-1 {
      display: block;
    }
  }
  @media (min-width: 374px) {
    font-size: 12px;
  }
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media (min-width: 1280px) {
    padding: 8px 0;
  }
  .desktop-only {
    display: none;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
  &.ribbon-ab-test-a {
    //background: green;
    padding: 16px 0 20px;
    @media (min-width: 1024px) {
      //padding: 18px 0 22px;
    }
  }
`

const RibbonContainer = styled.div`
  transition: none;
  background: rgba(203, 230, 239, 0.96);
  //backdrop-filter: blur(48px);
  border-radius: 4px;
  height: 42px;
  margin-top: 4px;
  width: 100%;
  z-index: 11;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow:
    0px 11px 15px 0px rgba(0, 87, 82, 0.08),
    0px 9px 46px 0px rgba(0, 87, 82, 0.06),
    0px 24px 38px 0px rgba(0, 87, 82, 0.06);
  @media (max-width: 767px) {
    position: absolute;
    width: 98%;
    left: 0;
    right: 0;
    margin: auto;
    top: 104px;
  }
  em.display-nicely {
    display: inline;
    @media (min-width: 431px) and (max-width: 768px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    height: 40px;
    em {
      display: inline;
    }
    margin-top: 0;
    margin-bottom: 4px;
  }
`

const Ribbon = (props) => {
  const { contentfulHeaderRibbon, PROMO_VARIANT } = props
  const [isClosed, setIsClosed] = useState(false)
  const ribbonEl = useRef(null)
  const userState = useContext(UserContext)

  if (!contentfulHeaderRibbon) {
    return null
  }

  let ribbonHtml = renderRichTextDinamo(contentfulHeaderRibbon.ribbonText?.raw)
  let ribbonHref = contentfulHeaderRibbon.ribbonLink
  if (ribbonMap[PROMO_VARIANT]?.copy && userState?.customer?.firstname) {
    ribbonHtml = ribbonMap[PROMO_VARIANT].copy.replace(
      "{name}",
      userState?.customer?.firstname
    )
    ribbonHref = ribbonMap[PROMO_VARIANT].href
  }
  const getRibbonAttrs = () => {
    let obj = {
      destination: ribbonHref,
      text: ribbonHtml?.replace(/(<([^>]+)>)/gi, ""),
      type: "ribbon",
      module_location: "ribbon",
    }
    return obj
  }

  /*
   * const closeRibbon = () => {
   *   segmentEvent("Ribbon Closed", getRibbonAttrs())
   *   setIsClosed(true)
   *   if (props.isMouseInHeader) {
   *     props.isMouseInHeader.current = false
   *   }
   * }
   */

  const goTo = (e) => {
    segmentEvent("Marketing CTA Clicked", getRibbonAttrs())
    e.preventDefault()
    navigate(ribbonHref)
    return false
  }

  const content = (
    <TextElement
      element="p"
      text={ribbonHtml}
      className="body--regular text-center text-master-base-black"
      styles={{
        width: "100%",
        color: theme.wild.color.master.base.black,
      }}
    />
  )

  return (
    <RibbonContainer
      style={
        isClosed
          ? { display: "none" }
          : { backgroundColor: theme.wild.color.men.brand.blue[40] }
      }
      className={classNames("text-center ribbon-container men", {
        /*
         * "sm:!top-[-44px]": true,
         * "mobile-variant-ab": true,
         */
      })}
    >
      {ribbonHref ? (
        <>
          <RibbonLink
            onClick={goTo}
            ref={ribbonEl}
            href={ribbonHref}
            id={`ribbon`}
            className={`body--regular text-master-base-black text-center`}
            dangerouslySetInnerHTML={{ __html: ribbonHtml }}
          ></RibbonLink>
          {/*NT2-17932 FNF Ribbon | Men's ribbon has an x to close
<CloseIconWrap onClick={closeRibbon}>*/}
          {/*  <CloseIcon />*/}
          {/*</CloseIconWrap>*/}
        </>
      ) : (
        <div className="w-full">{content}</div>
      )}
    </RibbonContainer>
  )
}

export default Ribbon
