import React from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "@rebass/grid/emotion"
import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import InstagramIcon from "../../../../assets/icons/wild/social-icon-instagram-dark.svg"
import TiktokIcon from "../../../../assets/icons/wild/social-icon-tiktok-dark.svg"
import FacebookIcon from "../../../../assets/icons/wild/social-icon-facebook-dark.svg"
import LinkedinIcon from "../../../../assets/icons/wild/social-icon-linkedin-dark.svg"

const MenuBottom = styled(Flex)`
  margin: auto 24px 64px;
`

const Menu = styled(Flex)`
  border: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  border-radius: 4px;
  justify-content: space-around;

  &.social-menu {
    flex: 1;
    padding: 0;
  }

  &.account-menu {
    margin-right: 8px;
  }

  &.account-menu a {
    width: 56px;

    svg {
      width: 16px;
    }
  }

  &.logout-menu {
    width: 80px;
    > div {
      max-width: 80px;
    }
  }
`

const MenuItem = styled(Box)`
  width: 100%;
  max-width: 56px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;    
    margin: 0 auto;
  }
`

const BottomNavigationMobile = (props) => {
  return (
    <MenuBottom>
      <Menu className="social-menu">
        <MenuItem>
          <a href="https://www.instagram.com/nutrafol/" target="_blank" rel="noopener noreferrer" aria-label={`Instagram (Opens in new tab)`}>
            <InstagramIcon />
          </a>
        </MenuItem>
        <MenuItem>
          <a href="https://www.tiktok.com/@nutrafol" target="_blank" rel="noopener noreferrer" aria-label={`Tiktok (Opens in new tab)`}>
            <TiktokIcon />
          </a>
        </MenuItem>
        <MenuItem>
          <a href="https://www.facebook.com/nutrafol/" target="_blank" rel="noopener noreferrer" aria-label={`Facebook (Opens in new tab)`}>
            <FacebookIcon />
          </a>
        </MenuItem>
        <MenuItem>
          <a href="https://www.linkedin.com/company/nutrafol/" target="_blank" rel="noopener noreferrer" aria-label={`Linkedin (Opens in new tab)`}>
            <LinkedinIcon />
          </a>
        </MenuItem>
      </Menu>
    </MenuBottom>
  )
}
export default BottomNavigationMobile