import React, { useState } from "react"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { Link } from "gatsby"
import LogoutButton from "../../../account/logoutbutton"
import breadcrumbs from "../../../../images/breadcrumbs.png"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

const LowerWrapContainer = styled(Flex)`
  background: #fff;
  &.non-account-path {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 0;
    margin-top: 0;
    padding-left: 8px;
    align-items: flex-end;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 11px 15px 0px #00575214;
    box-shadow: 0px 9px 46px 0px #0057520f;
    box-shadow: 0px 24px 38px 0px #0057520f;
  }
  &:not(.is-menu) {
    align-items: center;
    padding: 8px 9px;
    @media screen and (min-width: 768px) {
      padding: 4px 8px 6px;
      align-items: flex-end;
    }
    @media screen and (min-width: 1024px) {
      padding: 8px 16px 14px;
    }
  }
  &.is-menu .account-menu-item {
    padding-top: 4px;
    padding-bottom: 8px;
  }
  .simple-account {
    padding-bottom: 0;
    margin-right: 3px;
    @media (min-width: 1024px) {
      margin-right: 11px;
    }
  }
  .menu-item-wrap {
    text-align: center;
    position: relative;
    width: auto !important;
    flex-grow: 1;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
      width: 25% !important;
      flex-grow: 0;
    }
    @media screen and (min-width: 1024px) {
      padding-top: 10px;
    }
    &--current {
      @media (min-width: 768px) {
        overflow: hidden;
        :after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 0;
          height: 6px;
          border-radius: 3px;
          width: 100%;
          background-color: #008078;
          z-index: 1;
        }
      }
    }
  }
  .account-menu-item {
    margin-top: 9px;
    font-size: 12px !important;
    @media screen and (min-width: 320px) {
      font-size: 14px !important;
    }
    @media screen and (min-width: 1024px) {
      font-size: 16px !important;
    }
    @media screen and (max-width: 1024px) {
      margin-top: 4px;
    }
  }
`

const DropdownBody = styled(Box)`
  position: absolute;
  top: 92px;
  right: 0;
  background-color: white;
  z-index: 30;
  padding: 8px 16px;
`

const MenuDropdown = (props) => (
  <DropdownBody
    width="108px"
    className="hide-md-up"
    display={props.isOpen ? "block" : "none"}
  >
    <Flex flexDirection={"column"} className="hide-md-up gap-1.5">
      {props.dropdownItems.map((item, index) => {
        if (item.title === "Log Out") {
          return (
            <LogoutButton
              key={item.title}
              isAccountLink={true}
              fontClass={`body--large text-master-base-alt-alt`}
            />
          )
        } else {
          return (
            <Link
              to={item.link}
              key={item.title}
              onClick={() => handleAccountNavClick(item.link, item.title)}
            >
              <Box>
                <TextElement
                  text={item.title}
                  className="body--large text-master-base-alt-alt"
                />
              </Box>
            </Link>
          )
        }
      })}
    </Flex>
  </DropdownBody>
)

const DropdownParent = styled(Box)`
  height: 35px;
  /* fix for ios */
  line-height: 35px;
  background: url(${breadcrumbs}) no-repeat center center;
  background-size: 50%;
`

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  return (
    <DropdownParent
      onClick={toggleDropdown}
      width="40px"
      className="hide-md-up"
      position="relative"
    >
      <MenuDropdown
        isOpen={isOpen}
        toggleDropdown={toggleDropdown}
        dropdownItems={dropdownItems}
      ></MenuDropdown>
    </DropdownParent>
  )
}
const ACCPATH = "/account/"

const menuItems = [
  {
    title: "myNutrafol",
    link: ACCPATH,
    class: "account-menu-item",
    parentClass: "menu-item-wrap",
  },
  {
    title: "Subscriptions",
    link: "/account/subscriptions/",
    class: "account-menu-item",
    parentClass: "menu-item-wrap",
  },
  {
    title: "Order History",
    link: "/account/orders/",
    class: "account-menu-item",
    parentClass: "menu-item-wrap",
  },
  {
    title: "Profile",
    link: "/account/profile/",
    class: "account-menu-item",
    parentClass: "menu-item-wrap",
  },
  {
    title: "Rewards",
    link: "/account/rewards/",
    class: "account-menu-item",
    parentClass: "menu-item-wrap show-md-up",
  },
  {
    title: "Log Out",
    link: "/logout",
    class: "account-menu-item",
    parentClass: "menu-item-wrap show-md-up",
  },
]

const dropdownItems = [
  {
    title: "Rewards",
    link: "/account/rewards/",
    class: "account-menu-item--dropdown",
  },
  {
    title: "Log Out",
    link: "/logout",
    class: "account-menu-item show-md-up--dropdown",
  },
]

const isCurrent = (link) => {
  const pathname = typeof window !== "undefined" ? window.location.pathname : ""
  const search = typeof window !== "undefined" ? window.location.search : ""
  //console.log(link, pathname, search,)
  if (search.includes("subId")) {
    return link === "/account/subscriptions/"
  } else if (link === ACCPATH) {
    return pathname === ACCPATH
  } else {
    return pathname.includes(link)
  }
}

const handleAccountNavClick = (destination, text) => {
  segmentEvent("Account Navigation Clicked", {
    destination,
    text,
  })
}

const LowerMenu = ({ menuItems, dropdownItems }) => {
  return (
    <LowerWrapContainer className="lower-wrap is-menu">
      {menuItems.map((item) => {
        if (item.title === "Log Out") {
          return (
            <Box
              width={[1 / 4, 1 / 4, 1 / 7, 1 / 7]}
              className={`${item.parentClass} show-md-up`}
              key={item.title}
            >
              <LogoutButton
                isAccountLink={true}
                fontClass={`account-menu-item show-md-up body--large text-master-base-alt-alt`}
              />
            </Box>
          )
        } else {
          return (
            <Box
              width={[1 / 4, 1 / 4, 1 / 7, 1 / 7]}
              key={item.link}
              className={
                item.parentClass +
                (isCurrent(item.link)
                  ? ` ${item?.parentClass?.split(" ")[0]}--current`
                  : "")
              }
            >
              <Link
                to={item.link}
                onClick={() => handleAccountNavClick(item.link, item.title)}
                className={
                  `account-menu-item title--large text-master-base-alt-alt` +
                  (isCurrent(item.link)
                    ? ` text-master-primary-default font-bold`
                    : "")
                }
              >
                <TextElement
                  text={item.title}
                  className={
                    `account-menu-item title--large text-master-base-alt-alt` +
                    (isCurrent(item.link)
                      ? ` body--large-b text-master-primary-default font-bold`
                      : " body--large")
                  }
                />
              </Link>
            </Box>
          )
        }
      })}
      <DropdownMenu dropdownItems={dropdownItems} />
    </LowerWrapContainer>
  )
}

const LowerWrap = (props) => {
  const { isAccountPage } = props

  if (isAccountPage) {
    return <LowerMenu menuItems={menuItems} dropdownItems={dropdownItems} />
  } else {
    return null
  }
}

export default LowerWrap
