import React, {useState, useEffect, useRef} from "react"
import classNames from 'classnames'
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"

import Logo from "./logo"
import RibbonSimple from "./ribbonsimple"

const mobileFold = 900

const HeaderWrap = styled.header`
  &[data-mousedown] {
    *:focus {
      outline: none;
    }
  }
  @media (min-width: 1024px) {
    height: 80px;
  }
`
const HeaderContainer = styled.div`
  padding: 12px 15px !important;
  @media (min-width: 900px) {
    padding: 20px 15px !important;
  }
  @media (min-width: 1024px) {
    padding: 0 25px !important;
  }
`
const ContentsContainer = styled.div`
  @media (min-width: ${mobileFold}px) {
    font-size: 0;
    .left-wrap {
      width: calc(50% - 75px);
      align-self: center;
    }
    .center-wrap {
      width: 150px;
      align-self: center;
    }
    .right-wrap {
      width: calc(50% - 75px);
      align-self: center;
    }
  }
`

const HeaderReallySimple = (props) => {
  const isMouseInHeader = useRef(false)
  const [yVal, setYVal] = useState(0)

  useEffect(() => {
    const bodyElem = document.querySelectorAll("body")[0]

    const handleScroll = (e) => {
      if (!isMouseInHeader.current) {
        if (document.documentElement.scrollTop <= 85) {
          if (bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.remove("pagescrolled")
          }
        } else if (yVal > document.documentElement.scrollTop) {
          if (bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.remove("pagescrolled")
          }
        } else {
          if (!bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.add("pagescrolled")
          }
        }
        setYVal(document.documentElement.scrollTop)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [yVal, setYVal])

  return (
    <>
      <HeaderWrap
        data-mousedown
        className={classNames("header-wrap header-really-simple", {
          mousedown: props.mouseDown,
        })}
        onMouseEnter={() => (isMouseInHeader.current = true)}
        onMouseLeave={() => (isMouseInHeader.current = false)}
        onTouchEnd={() =>
          setTimeout(() => {
            isMouseInHeader.current = false
          })
        }
      >
        <HeaderContainer className="container">
          <ContentsContainer className="wrap">
            <Flex flexWrap="wrap">
              <Box width={[1 / 4, null, 3 / 10]} className="left-wrap"></Box>
              <Box width={[1 / 2, null, 4 / 10]} className="center-wrap">
                <Logo
                  site={
                    typeof window !== `undefined`
                      ? window.location.pathname.split("/").includes("men") &&
                        "men"
                      : ""
                  }
                />
              </Box>
              <Box width={[1 / 4, null, 3 / 10]} className="right-wrap"></Box>
            </Flex>
          </ContentsContainer>
        </HeaderContainer>
        {props.withRibbon ? (
          <Box mt={["4px"]}>
            <RibbonSimple />
          </Box>
        ) : null}
      </HeaderWrap>
    </>
  )
}

export default HeaderReallySimple
