import React, { useState, useRef, useContext } from "react"
import { navigate } from "gatsby"
import { TextElementNova } from "../../../../../nutrafol-ui-kit/src/TextElementNova"
import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { RibbonLink, RibbonContainer } from "./ribbon.styled"
import { renderRichTextDinamo } from "../../../../utils/contentful"
import UserContext from "../../../../context/UserContext"

const ribbonMap = {
  subpush: {
    copy: "Put your hair growth plan on autopilot with a Nutrafol subscription <br class='block md:hidden'/> and  <span class='font-dinamoBold'>see results in as little as 3 months.</span>",
    href: null,
  },
  pick_your_booster: {
    copy: "{name}, celebrate with a free booster. <span class='font-dinamoBold'>Pick yours!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  pay_it_forward: {
    copy: "{name}, keep growing with $5 off. <span class='font-dinamoBold'>Apply now!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  upgrade_booster: {
    copy: "{name}, save 5% on renewals. <span class='font-dinamoBold'>Add your booster!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  free_gift: {
    copy: "{name}, celebrate with a gift. <span class='font-dinamoBold'>Pick yours now!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  refer_a_friend: {
    copy: "{name}, <i>refer friends and save!</i>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
}

/**
 **
 * https://nutrafol.atlassian.net/browse/NT2-7647
 * see Collagen Ribbon | AB Test in VWO for details
 */

const Ribbon = (props) => {
  const { PROMO_VARIANT = "mayGwp", contentfulHeaderRibbon, location } = props

  const [isClosed] = useState(false)
  const ribbonEl = useRef(null)
  const userState = useContext(UserContext)

  if (!contentfulHeaderRibbon && !PROMO_VARIANT) {
    return null
  }

  let ribbonHtml = renderRichTextDinamo(contentfulHeaderRibbon?.ribbonText?.raw)
  let ribbonHref = contentfulHeaderRibbon?.ribbonLink
  if (ribbonMap[PROMO_VARIANT]?.copy) {
    ribbonHtml = ribbonMap[PROMO_VARIANT].copy.replace(
      "{name}",
      userState?.customer?.firstname
    )
    ribbonHref = ribbonMap[PROMO_VARIANT].href
  }

  const getRibbonAttrs = () => {
    let obj = {
      destination: ribbonHref,
      text: ribbonHtml?.replace(/(<([^>]+)>)/gi, ""),
      type: "ribbon",
      module_location: "ribbon",
    }
    return obj
  }

  const goTo = (e) => {
    segmentEvent("Marketing CTA Clicked", getRibbonAttrs())
    e.preventDefault()
    navigate(ribbonHref)
    return false
  }

  const content = (
    <TextElementNova
      element="p"
      text={ribbonHtml}
      className="Body-10-Dia-Normal text-center text-nova-base-black"
      styles={{
        width: "100%",
      }}
    />
  )

  return (
    <RibbonContainer
      style={
        !isClosed
          ? { backgroundColor: `${theme.wild.color.nova.base.solid[20]}` }
          : { display: "none" }
      }
      className={`text-center ${location?.pathname?.replaceAll("/", "-")}`}
    >
      {ribbonHref ? (
        <>
          <RibbonLink
            onClick={goTo}
            ref={ribbonEl}
            href={ribbonHref}
            id={`ribbon`}
            className={`Body-10-Dia-Normal text-center text-nova-base-black`}
            dangerouslySetInnerHTML={{ __html: ribbonHtml }}
          ></RibbonLink>
        </>
      ) : (
        <div className="w-full">{content}</div>
      )}
    </RibbonContainer>
  )
}

export default Ribbon
