import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"

//import LogoSVGMobile from "../../../../assets/icons/wild/nutrafol-logo-mobile.svg"
import LogoSVGDesktop from "../../../../assets/icons/wild/nutrafol-logo-v2.svg"

const Logo = (props) => {
  const LogoArea = styled.div`
    ${queries.large`
      padding: 15px 0;
    `}
  `

  const LogoImageHolder = styled.span`
    svg {
      width: 110px;
      margin: 0 auto;
      &.simple-logo-mobile {
        width: 18px;
        margin: 4px auto;
      }

      @media (min-width: 1024px) {
        width: 156px;
      }
    }

    //&.generic-page {
    //  @media (max-width: 1023px) {
    //    .LogoSVGDesktop {
    //      width: 100%;
    //      max-width: 157px;
    //    }
    //  }
    //}
  `

  const { isSimple, site } = props

  return (
    <LogoArea className="logo-wrap" onClick={props.handleNavLogoExitClick}>
      {site && site === "skin" ? (
        <a href={`${process.env.GATSBY_MAGENTO_URL}${site}/`}>
          <LogoImageHolder
            className={props.isAccountPage ? "is-account-page" : "generic-page"}
          >
            {/*{isSimple && (*/}
            {/*  <LogoSVGMobile*/}
            {/*    className="hide-lg-up simple-logo-mobile"*/}
            {/*    alt="home"*/}
            {/*  />*/}
            {/*)}*/}
            {/*<LogoSVGDesktop*/}
            {/*  className={`${isSimple ? "show-lg-up" : ""} LogoSVGDesktop`}*/}
            {/*  title="home"*/}
            {/*/>*/}

            <LogoSVGDesktop className={`LogoSVGDesktop`} title="home" />
          </LogoImageHolder>
        </a>
      ) : (
        <Link to={!!site ? `/${site}/` : "/"}>
          <LogoImageHolder
            className={props.isAccountPage ? "is-account-page" : "generic-page"}
          >
            {/*{isSimple && (*/}
            {/*  <LogoSVGMobile*/}
            {/*    className="hide-lg-up simple-logo-mobile"*/}
            {/*    alt="home"*/}
            {/*  />*/}
            {/*)}*/}
            {/*<LogoSVGDesktop*/}
            {/*  className={`${isSimple ? "show-lg-up" : ""} LogoSVGDesktop`}*/}
            {/*  title="home"*/}
            {/*/>*/}
            <LogoSVGDesktop className={`LogoSVGDesktop`} title="home" />
          </LogoImageHolder>
        </Link>
      )}
    </LogoArea>
  )
}

export default Logo
